import http from "./http";
import { getJwt } from "./auth";
import { postify } from "./utilities";

/**--------------------------------------
 ** Build POST Api string
 * --------------------------------------
 * @param {string} path
 * @returns {object}
 */
export async function postAPI(path, data) {
  return getJwt()
    .then(() => http.post(`post/${path}.php`, postify(data)))
    .catch((e) => {
      console.error(e);
      return { data: { error: e } };
    });
}

/**--------------------------------------
 ** Submit Shell Houston Open form to Salesforce
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
 export const submitShellForm = async (values) => {
  console.log('Shell form submission - Starting', values);
  
  try {
    // Log before making API call
    console.log('Shell form - About to call postAPI');
    const { data } = await postAPI("submit/shell/index", values);
    
    // Log response
    console.log('Shell form - Response received:', data);
    
    !data.success && !data.error && console.log('Shell form - Unusual response:', data);
    return data;
  } catch (error) {
    console.error('Shell form - Error during submission:', error);
    return { error: error.message || 'Unknown error during form submission' };
  }
};
/**--------------------------------------
 ** Submit new lead
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const submitLead = async (values) => {
  const { data } = await postAPI("submit/leads/index", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Submit new lead
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const submitConsent = async (values) => {
  const { data } = await postAPI("submit/consent/index", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Create user
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const createUser = async (values) => {
  const { data } = await postAPI("users/create", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Verify consent
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const verifyConsent = async (values) => {
  const { data } = await postAPI("consent/edit", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Verify lead
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const verifyLead = async (values) => {
  const { data } = await postAPI("leads/edit", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Update User
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const updateUser = async (values) => {
  const { data } = await postAPI("users/edit", values);
  !data.success && !data.error && console.log(data);
  return data;
};

/**--------------------------------------
 ** Delete User
 *---------------------------------------
 * @param {object} values submitted values to edit
 * @return {object}
 */
export const deleteUser = async (values) => {
  const { data } = await postAPI("users/delete", values);
  !data.success && !data.error && console.log(data);
  return data;
};
