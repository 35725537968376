import React, { useEffect, useState } from "react";
import "../index.css";
import { useForm, Controller } from "react-hook-form";
import { updateTitle, scrollToTop } from "../services/utilities";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "yup-phone";
import * as yup from "yup";
import Form from "../components/common/inputs";
import { CardInline } from "../components/common/cards";
import { submitShellForm } from "../services/post";
// import { submitConsent } from "../services/post";
import { Nav } from "../components/nav";
import Button from "../components/common/button";
// Zipcode list
import zipcodes from "../assets/zipcodes/all.json";
// Media
import reliantLogo from "../assets/images/reliant-logo.png";

/** -------------------------------
 *# Generic Customer Consent Form
 * --------------------------------
 */
const Shell = (props) => {
    // Update title
    updateTitle("Customer Consent");

    const { customer_id, agent_id } = props;

    // Set state
    const [notServiceable, toggleServiceable] = useState(false);
    const [clear, setClear] = useState("false");
    const [show, toggleShow] = useState(true);
    const [loading, toggleLoading] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        // Clean up
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Responsive design to make title look good
    const imageStyle = windowWidth <= 532 ? { display: "none" } : { visibility: "hidden" };

    // Schema for shell
    const schema = yup.lazy((value) => {
        if (value.PostalCode === "" || value.Phone === "") {
            scrollToTop();
        }
        if (value !== "undefined") {
            return yup.object().shape({
                FirstName: yup.string().required("Required"),
                LastName: yup.string().required("Required"),
                Street: yup.string(),
                City: yup.string(),
                // consentReliant: yup.string().required("Required").matches("yes", "Thank you for visiting this site. Your information has not been collected."),
                Email: yup.string().email("Email not valid"),
                // Phone: yup.string().min(12).max(12).required("Required"),
                Phone: yup
                    .string()
                    .required("Required")
                    .matches(/^\d{3}-\d{3}-\d{4}$/),
                PostalCode: yup.string().test("zip", "Not a valid zip", function (value) {
                    // If empty string, it's valid (optional field)
                    if (!value || value === "") return true;
                    // Otherwise must be at least 5 characters
                    return value.length >= 5;
                }),
                IsReliantCustomer: yup.string(),
            });
        }
        return yup.mixed().notRequired();
    });

    // Handle validation
    const { register, handleSubmit, errors, reset, control } = useForm({
        resolver: yupResolver(schema),
    });

    // Form variables
    const states = [
        { id: "TX", name: "Texas" },
        { id: "IL", name: "Illinois" },
        { id: "MA", name: "Massachusetts" },
        { id: "MD", name: "Maryland" },
        { id: "NJ", name: "New Jersey" },
        { id: "OH", name: "Ohio" },
        { id: "PA", name: "Pennsylvania" },
    ];

    // Check for qualified zip
    const validateZip = (zip) => {
        // Check once code has been completely entered
        if (zip.length === 5) {
            zipcodes.includes(zip) ? toggleServiceable(false) : toggleServiceable(true);
        } else {
            toggleServiceable(false);
        }
        return zip;
    };

    // Handle form submit
    const onSubmit = async (data, e) => {
        if (e) e.preventDefault();

        // Add explicit validation for the phone field
        if (!data.Phone || data.Phone.trim() === "") {
            toast.error("Phone number is required");
            scrollToTop();
            return; // Stop the submission if phone is empty
        }

        toggleLoading(true);

        // Preserve customer_id and agent_id if they exist
        if (customer_id) data.customer_id = customer_id;
        if (agent_id) data.agent_id = agent_id;

        const sanitizedData = {
            ...data,
            Email: data.Email ? data.Email.toUpperCase() : "", // Convert email to uppercase
            Phone: data.Phone ? data.Phone.replace(/\D/g, "") : "", // Remove all non-digit characters
        };

        // For debugging
        console.log("Submitting data:", sanitizedData);

        const { success, error } = await submitShellForm(sanitizedData);
        if (success) {
            console.log(success);
            scrollToTop();
            resetForm();
            toast.success(success.message, {
                autoClose: 20000,
            });
        } else {
            scrollToTop();
            error && console.log(error);
            toast.error(error);
        }
        toggleLoading(false);
    };

    // Initialize form
    const resetForm = () => {
        setClear("true");
        reset();
        setClear("false");
    };

    return (
        <>
            <Nav id={customer_id} />
            {customer_id && show && (
                <section className="mini mt-3">
                    <CardInline id="cid" title="Customer ID:" text={customer_id} hide={() => toggleShow(false)} />
                </section>
            )}
            <section className="mt-3 form">
                <div className="title-block shell-title-block" style={{ margin: "0 auto" }}>
                    <img src={reliantLogo} alt="Reliant logo" />
                    <h3 className="sub-title" style={{ fontSize: "1.25rem" }}>
                        Consent Form
                    </h3>
                    <img src={reliantLogo} style={imageStyle} alt="Reliant logo" />
                </div>
                <div className="container">
                    <form className="form contact-us" onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className="row">
                            <small className="header">Consumer's Information</small>
                            <hr />
                        </div> */}
                        <div className="row">
                            <Form.Input label="First Name" name="FirstName" placeholder="Enter first name" required={true} clear={clear} register={register} error={errors.FirstName?.message} />
                            <Form.Input label="Last Name" name="LastName" placeholder="Enter last name" required={true} clear={clear} register={register} error={errors.LastName?.message} />
                            {/* <Controller name="Phone" defaultValue="" control={control} render={(props) => <Form.Input type="phone" label="Phone number" required={true} name="Phone" placeholder="___ - ___ - ____" onChange={(e) => props.onChange(e)} clear={clear} error={errors.Phone?.message} />} /> */}
                            <Controller name="Phone" defaultValue="" control={control} render={(props) => <Form.Input type="phone" label="Phone number" required={true} name="Phone" placeholder="___ - ___ - ____" onChange={(e) => props.onChange(e)} clear={clear} error={errors.Phone?.message} />} />
                        </div>
                        <hr />
                        {/* <div className="row">
                            <small className="header">Consumer’s Contact information</small>
                            <hr />
                        </div> */}
                        {/* justify-content: space-between; */}
                        <div className="row">
                            <Form.Input label="Street" name="Street" placeholder="Street" clear={clear} register={register} error={errors.Street?.message} />
                            <Form.Input label="City" name="City" placeholder="Enter city" clear={clear} register={register} error={errors.City?.message} />
                            <Form.Select label="State" name="StateCode" values={states} selected="TX" clear={clear} register={register} error={errors.StateCode?.message} />
                            <Controller name="PostalCode" defaultValue="" control={control} render={(props) => <Form.Input type="zipcode" label="Zip Code" name="PostalCode" placeholder="54123" onChange={(e) => props.onChange(validateZip(e))} clear={clear} error={errors.PostalCode?.message} />} />
                        </div>
                        {notServiceable && (
                            <div id="serviceable">
                                <p>The provided zipcode is not currently serviceable.</p>
                            </div>
                        )}
                        {/* Remove / add spaced-out */}
                        <div className="row">
                            <Form.Input type="email" label="Email Address" name="Email" placeholder="Enter email address" clear={clear} register={register} error={errors.Email?.message} />
                            <Form.Select
                                label="Are you a Reliant customer?"
                                name="IsReliantCustomer"
                                values={[
                                    { id: "yes", name: "Yes" },
                                    { id: "no", name: "No" },
                                ]}
                                selected="no"
                                clear={clear}
                                register={register}
                                error={errors.IsReliantCustomer?.message}
                            />
                            {/* <Form.Select label="Interested in Residential or Small Business" name="Customer_Type__c" values={residentialOrBusiness} clear={clear} register={register} error={errors.Customer_Type__c?.message} /> */}
                            <input type="hidden" name="Brand__c" value="Reliant" ref={register} />
                            <input type="hidden" name="RecordTypeId" value="012PA000002ElvZYAS" ref={register} />
                        </div>

                        <div className="row">
                            <p>
                                By clicking, <strong>“Submit”</strong> you agree that Reliant Energy Retail Services, LLC (“Reliant”) may contact you about energy products and services and other home services that Reliant may make available through its affiliates or third parties, and that your telephone, email address, and/or mobile number you provide on this form has been provided by you, belongs to you, and is accurate. Furthermore, you authorize Reliant to contact you for marketing purposes at the telephone number, mobile number or email address you provide on this form using automated telephone technology including auto-dialers and text messages, even if your telephone or mobile number is currently listed on any federal, state, or
                                Reliant’s corporate “Do Not Call” list. You are not required to give your consent as a condition of purchase.
                            </p>
                        </div>
                        {/* <div className="row shell-form">
                            <Form.RadioInline label="Reliant Consent" values={consent.Reliant} required={true} clear={clear} register={register} error={errors.consentReliant?.message} style={{ border: "2px solid blue" }} />
                        </div> */}
                        <div className="row">
                            <hr />
                        </div>
                        <Button text="Submit" className="btn btn-success" type="submit" loading={loading} />
                    </form>
                </div>
            </section>
        </>
    );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default Shell;
